<template>
  <div v-if="profiledata && profiledata.length > 0" id="profile-header" class="profile-body">
    <div class="profile-card" :style="profileCardStyle">
      <div class="profile-header">
        <img :src="profiledata[0].cdn_pfp_image" :alt="profiledata[0].display_name_text" class="profile-pic">
        <div class="profile-info">
          <div class="profile-name">{{ profiledata[0].display_name_text }}</div>
          <div><i class="fas fa-home"></i> {{ profiledata[0].shops }}</div>
          <div><i class="fas fa-map-marker-alt"></i> {{ profiledata[0].location }}</div>
          <div><i class="fas fa-user"></i> {{ profiledata[0].display_name_text }}</div>
          <div><i class="fas fa-book"></i> {{ profiledata[0].books_open_boolean }}</div>
        </div>
      </div>
      <div class="profile-description">{{ profiledata[0].bio_text }}</div>
      <button class="book-button" :style="bookButtonStyle" @click="showInquiryModal = true">
        <i class="fas fa-calendar"></i> BOOK WITH ME
      </button>
    </div>
    <transition name="slidefade">
    <inquiry-modal
        :showModal="showInquiryModal"
        :artistData="profiledata"
        @close="showInquiryModal = false"
    ></inquiry-modal>
    </transition>
  </div>
</template>

<script>
import InquiryModal from './InquiryForm.vue';

export default {
  name: 'ProfileHeader',
  components: {
    InquiryModal
  },
  data() {
    return {
      showInquiryModal: false
    };
  },
  props: {
    profiledata: {
      type: Array,
      required: true
    }
  },
  computed: {
    profileCardStyle() {
      return null
      // Your computed style for profile card
    },
    bookButtonStyle() {
      return null
      // Your computed style for book button
    }
  }
};
</script>

<style scoped>

</style>
