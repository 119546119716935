// src/store.js
import { createStore } from 'vuex';

export default createStore({
    state: {
        artist: ''
    },
    mutations: {
        setArtist(state, artist) {
            state.artist = artist;
        }
    },
    actions: {
        updateArtist({ commit }, artist) {
            commit('setArtist', artist);
        }
    },
    getters: {
        artist: state => state.artist
    }
});
