<template>
  <div id="app" :style="pageStyle" class="app-body" ref="scrollContainer">
    <!--
    <button v-if="artist" @click="closeArtist" class="floating-close-button">
      <i class="fas fa-times"></i> Close
    </button>
    -->
    <!-- Loading Splash Screen -->
    <transition name="splash">
      <div v-if="isLoading" class="loading-splash">
        <div class="spinner"></div>
      </div>
    </transition>

    <ProfileHeader :profiledata="artistData" />

    <div :class="['search-wrapper', { 'has-artist': hasArtist }]">

      <div id="icon-container-left" class="search-icon-container" @click="toggleFilterModal">
        <span class="iconify filter-icon" data-icon="oui:filter"></span>
      </div>



      <input
          type="text"
          id="search-input"
          v-model="searchQuery"
          @focus="onFocus"
          @blur="onBlur"
          @input="onSearchInput"
          :placeholder="searchPlaceholder"
      >

      <div id="icon-container-right" class="search-icon-container" @click="clearSearch">
        <span class="iconify search-icon" data-icon="lets-icons:search-alt" @click="performSearch"></span>
        <span class="iconify clear-search-icon hidden" data-icon="ic:round-close" @click="resetSearch"></span>
      </div>
    </div>



    <button id="filter-button" class="hidden" @click="toggleFilterModal">Filter Search</button>

    <!-- Modal Start -->
    <transition name="slidefade">
    <div v-if="showFilterModal" class="modal-backdrop" @click="toggleFilterModal">
      <div class="filter-modal-content modal-content" @click.stop>
        <div id="filter-section" :class="{ expanded: filterSectionExpanded }">
          <strong>Location</strong>
          <div class="address-search">
            <input
                id="autocomplete"
                type="text"
                placeholder="Enter an address or city"
                class="address-input"
            />
            <select v-model="radius" class="radius-select">
              <option v-for="option in radiusOptions" :key="option" :value="option">
                {{ option }} km
              </option>
            </select>
          </div>
          <!-- Existing filter sections -->
          <div id="category-filter">
            <strong>Categories</strong>
            <div class="filter-buttons">
              <button @click="selectAllCategories">Select All</button>
              <button @click="clearAllCategories">Clear All</button>
            </div>
            <div id="category-options">
              <div>
                <input type="checkbox" id="category-Flash" name="category" value="Flash" checked>
                <label for="category-Flash">Flash</label>
              </div>
              <div>
                <input type="checkbox" id="category-Promotional" name="category" value="Promotional">
                <label for="category-Promotional">Promotional</label>
              </div>
              <div>
                <input type="checkbox" id="category-Tattoo" name="category" value="Tattoo" checked>
                <label for="category-Tattoo">Tattoo</label>
              </div>
            </div>
          </div>
          <div id="style-filter">
            <strong>Styles</strong>
            <div class="filter-buttons">
              <button @click="selectAllStyles">Select All</button>
              <button @click="clearAllStyles">Clear All</button>
            </div>
            <div id="style-options"></div>
          </div>
        </div>
        <button class="close-modal" @click="toggleFilterModal">
          <span class="iconify" data-icon="ic:round-close" data-inline="false"></span>
        </button>
      </div>
    </div>
    </transition>
    <!-- Modal End -->

    <div class="grid" id="search-results">
      <div class="grid-sizer"></div>
      <!-- Image containers will be appended here -->
    </div>



  </div>
</template>

<script>
import ProfileHeader from './components/ProfileHeader.vue';

import { mapState, mapActions } from 'vuex';
/* global Masonry, imagesLoaded, Fancybox, google */

export default {
  name: 'App',
  components: {
    ProfileHeader,
  },
  data() {
    return {
      searchQuery: '',
      isFocused: false,
      showFilterModal: false,
      styleOptions: [],
      filterSectionExpanded: false,
      currentPage: 1,
      resultsPerPage: 20,
      isLoading: false,
      typingTimer: null,
      typingInterval: 800,
      loadedResults: new Set(),
      baseURL: window.location.origin,
      artistData: [], // to store fetched artist data
      captionModalVisible: false, // to control the visibility of the caption modal
      modalContent: '', // Content for the caption modal
      profileUsername: '',
      artist: '',
      autocomplete: null,
      radius: 10,
      radiusOptions: [1, 5, 10, 20, 50, 100],
      searchPlace: null,
      stylesInitialized: false,
      selectedStyles: [],
      selectedCategories: ['Flash', 'Tattoo'],
      showBackToTop: false,
      showInquiryModal: false,

    };
  },
  computed: {
    ...mapState(['artist']),
    hasArtist() {
      return this.artist && this.artist.trim() !== '';
    },
    searchPlaceholder() {
      return this.artist ? `Search ${this.artist}'s portfolio` : '';
    },

    pageStyle() {
      if (this.artistData.length > 0) {
        let artist = this.artistData[0];
        return {
          backgroundColor: artist.bg_hex_text || '',
          backgroundImage: artist.background_image_image ? `url(${artist.background_image_image})` : ''
        };
      }
      return {};
    },
    profileBodyStyle() {
      if (this.artistData.length > 0) {
        let artist = this.artistData[0];
        return {
          backgroundColor: artist.style_profile_body_colour_text || 'rgba(18, 18, 18, 0.8)',
          color: artist.style_profile_font_colour_text || ''
        };
      }
      return {
        backgroundColor: 'rgba(18, 18, 18, 0.8)',
        color: ''
      };
    },
    buttonStyle() {
      if (this.artistData.length > 0) {
        let artist = this.artistData[0];
        return {
          backgroundColor: artist.style_profile_button_colour_text || '#f6f6f6',
          color: artist.style_profile_button_font_colour_text || '#121212',
          border: artist.style_profile_button_font_colour_text || '#121212'
        };
      }
      return {
        backgroundColor: 'none', //'#f6f6f6',
        color: '#121212',
        border: '2px solid black'
      };
    }
  },
  watch: {
    // eslint-disable-next-line
    searchQuery(newVal, oldVal) {
      const searchicon = document.querySelector('.search-icon');
      const clearicon = document.querySelector('.clear-search-icon');
      if (newVal && newVal.trim() !== '') {
        searchicon.classList.add('hidden');
        clearicon.classList.remove('hidden');
      } else {
        searchicon.classList.remove('hidden');
        clearicon.classList.add('hidden');
      }
    },

    showFilterModal(isVisible) {
      console.log("showFilterModal called with isVisible:", isVisible);
      if (isVisible && !this.stylesInitialized) {
        console.log("Initializing styles as modal is being shown and styles are not yet initialized.");
        this.initializeStyles();
      }
      this.showFilterModal = isVisible;
      console.log("New state of showFilterModal:", this.showFilterModal);
      const bodyWrapper = document.querySelector('.body-wrapper');
      if (isVisible) {
        bodyWrapper.classList.add('no-scroll');
      } else {
        bodyWrapper.classList.remove('no-scroll');
      }
    },

    artist: {
      async handler(newValue) {
        this.artistData = [];
        await this.fetchArtistData();
        this.applyBodyStyles();
        this.scrollToTop();

        const closeArtistButton = document.querySelector('.floating-close-button');
        if (closeArtistButton) {
          if (newValue && newValue.trim() !== '') {
            closeArtistButton.classList.remove('hidden');
          } else {
            closeArtistButton.classList.add('hidden');
          }
        }
      },
      immediate: true // if you want it to be called immediately upon component mount
    },
    artistData: {
      handler() {
        this.applyBodyStyles();
      },
      deep: true
    }
  },
  mounted() {
    window.vueInstance = this;
    console.log("Global Vue instance set:", window.vueInstance);
    console.log("Initial artist in Vuex store:", this.artist);


    this.handleArtistParam();
    this.fetchCategoriesAndStyles();
    this.performSearch();
    this.initializeFancybox();

    //window.addEventListener('scroll', this.onScroll);

    const bodyWrapper = document.querySelector('.body-wrapper');
    if (bodyWrapper) {
      bodyWrapper.addEventListener('scroll', this.onScroll);
    }

    const clearIcon = document.querySelector('.clear-search-icon');
    if (clearIcon) {
      console.log('clear-search-icon element found');
      clearIcon.addEventListener('click', this.resetSearch);
    } else {
      console.log('clear-search-icon element not found');
    }

    window.addEventListener('popstate', this.handleArtistParam);
    window.addEventListener('hashchange', this.handleArtistParam);

    document.body.addEventListener('click', (event) => {
      const instagramLink = event.target.closest('.instagram-link');
      const profileLink = event.target.closest('.profile-link');
      if (instagramLink) {
        const username = instagramLink.getAttribute('data-username');
        this.setArtist(username);
        console.log('Instagram link clicked:', username);
      } else if (profileLink) {
        const username = profileLink.getAttribute('data-username');
        this.setArtist(username);
        console.log('Profile link clicked:', username);
      }
    });

    this.$nextTick(() => {
      const bodyWrapper = document.querySelector('.body-wrapper');

      // Create the "Back to Top" button
      const backToTopButton = document.createElement('button');
      backToTopButton.className = 'back-to-top-button';

// Create the Iconify icon for the "Back to Top" button
      const upIcon = document.createElement('span');
      upIcon.className = 'iconify';
      upIcon.setAttribute('data-icon', 'mingcute:up-line');
      upIcon.setAttribute('data-inline', 'false');

// Append the icon to the "Back to Top" button
      backToTopButton.appendChild(upIcon);

// Create the "Close Artist" button
      const closeArtistButton = document.createElement('button');
      closeArtistButton.textContent = 'Close';
      closeArtistButton.className = 'floating-close-button hidden';

// Create the Iconify icon for the "Close Artist" button
      const closeIcon = document.createElement('span');
      closeIcon.className = 'iconify';
      closeIcon.setAttribute('data-icon', 'ic:round-close');
      closeIcon.setAttribute('data-inline', 'false');

// Append the icon to the "Close Artist" button
      closeArtistButton.appendChild(closeIcon);

// Append the buttons to the body
      document.body.appendChild(backToTopButton);
      document.body.appendChild(closeArtistButton);

      // Scroll event listener for backToTopButton
      if (bodyWrapper) {
        bodyWrapper.addEventListener('scroll', () => {
          if (bodyWrapper.scrollTop > 100) {
            backToTopButton.style.display = 'block';
          } else {
            backToTopButton.style.display = 'none';
          }

          if (bodyWrapper.scrollTop + bodyWrapper.clientHeight >= bodyWrapper.scrollHeight - 600 && !this.isLoading) {
            this.currentPage++;
            this.performSearch(this.currentPage);
          }
        });
      }

      // Click event listener for the buttons
      backToTopButton.addEventListener('click', () => {
        bodyWrapper.scrollTo({ top: 0, behavior: 'smooth' });
      });

      closeArtistButton.addEventListener('click', () => {
        this.closeArtist();
        closeArtistButton.style.display = 'none';
      });

      // Initial visibility based on this.artist
      if (this.artist) {
        closeArtistButton.style.display = 'block';
      } else {
        closeArtistButton.style.display = 'none';
      }

      // Watch for changes in this.artist to toggle the visibility of the closeArtistButton
      this.$watch('artist', (newVal) => {
        if (newVal) {
          closeArtistButton.style.display = 'block';
        } else {
          closeArtistButton.style.display = 'none';
        }
      });
    });
  },


  methods: {

    onFocus() {
      this.isFocused = true;
      const inputElement = document.getElementById('search-input');
      if (!this.hasArtist && this.searchQuery.trim() === '') {
        inputElement.classList.add('dim-background');
        inputElement.classList.remove('hide-background');
      }
    },

    onBlur() {
      this.isFocused = false;
      const inputElement = document.getElementById('search-input');
      if (!this.hasArtist && this.searchQuery.trim() === '') {
        inputElement.classList.remove('dim-background');
        inputElement.classList.remove('hide-background');
      }
    },

    scrollToTop() {
      const bodyWrapper = document.querySelector('.body-wrapper');
      bodyWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    },

    hexToRgba(hex, alpha) {
      let r = 0, g = 0, b = 0;
      // 3 digits
      if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      }
      // 6 digits
      else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },

    initAutocomplete() {
      const options = {
        types: ['geocode'],
      };
      const input = document.getElementById('autocomplete');
      this.autocomplete = new google.maps.places.Autocomplete(input, options);
      // Log to verify initialization
      console.log('Autocomplete initialized:', this.autocomplete);
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace();
        console.log('Place changed:', place);
        this.searchPlace = place;
      });
    },
    search() {
      const place = this.autocomplete.getPlace();
      if (place && place.geometry) {
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        this.$emit('search', { latitude, longitude, radius: this.radius });
      } else {
        alert('Please select an address or city from the dropdown');
      }
    },

    toggleFilterModal() {
      console.log("Toggling filter modal. Current state:", this.showFilterModal);
      this.showFilterModal = !this.showFilterModal;
      console.log("New state of showFilterModal:", this.showFilterModal);
      if (this.showFilterModal) {
        console.log("Modal is now visible. Initializing styles.");
        this.initializeStyles();
        this.attachStaticCheckboxListeners();
        this.scrollToTop()
      }
      this.filterSectionExpanded = !this.filterSectionExpanded;
      console.log("New state of filterSectionExpanded:", this.filterSectionExpanded);

    },

    initializeStyles() {
      this.$nextTick(() => {
        const styleContainer = document.getElementById('style-options');
        console.log("Checking for style-options element after nextTick:", styleContainer);
        if (styleContainer) {
          console.log("Populating style-options with checkboxes.");
          // Clear existing content to avoid duplication
          styleContainer.innerHTML = '';
          this.styleOptions.forEach(style => {
            console.log("Creating checkbox for style:", style);
            const checkbox = document.createElement('input');
            checkbox.type = 'checkbox';
            checkbox.id = `style-${style}`;
            checkbox.name = 'style';
            checkbox.value = style;
            checkbox.checked = this.selectedStyles.includes(style);

            checkbox.addEventListener('change', (event) => {
              if (event.target.checked) {
                this.selectedStyles.push(style);
              } else {
                this.selectedStyles = this.selectedStyles.filter(s => s !== style);
              }
              console.log("Updated selectedStyles:", this.selectedStyles);
              this.performSearch();
            });

            const label = document.createElement('label');
            label.htmlFor = `style-${style}`;
            label.innerText = style;

            const container = document.createElement('div');
            container.appendChild(checkbox);
            container.appendChild(label);

            styleContainer.appendChild(container);
          });
          console.log("Populated style-options with checkboxes.");
          this.stylesInitialized = true;
        } else {
          console.log("style-options element not found.");
        }
        this.initAutocomplete();
      });
    },

    attachStaticCheckboxListeners() {
      this.$nextTick(() => {
        const staticCheckboxes = document.querySelectorAll('#category-options input[type="checkbox"]');
        staticCheckboxes.forEach(checkbox => {
          checkbox.removeEventListener('change', this.updateSelectedCategories); // Remove any existing listeners
          checkbox.addEventListener('change', this.updateSelectedCategories); // Add new listener
        });
        console.log("Attached event listeners to static checkboxes.");
      });
    },

    updateSelectedCategories(event) {
      const category = event.target.value;
      if (event.target.checked) {
        this.selectedCategories.push(category);
      } else {
        this.selectedCategories = this.selectedCategories.filter(c => c !== category);
      }
      console.log("Updated selectedCategories:", this.selectedCategories);
      this.performSearch(1);
    },

    closeArtist() {
      console.log('clearing artist');
      this.artistData = [];
      this.artist = '';
      this.performSearch();
      this.scrollToTop();
    },
    /*
    applyBodyStyles() {
      if (this.artistData.length > 0) {
        let artist = this.artistData[0];
        document.body.style.backgroundColor = artist.style_profile_body_colour_text || '#f6f6f6';
        if (artist.background_image_image) {
          document.body.style.setProperty('--background-image-url', `url(${artist.background_image_image})`);
          document.body.classList.add('transitioned');
        } else {
          document.body.classList.remove('transitioned');
        }
      }
    },
    */

    applyBodyStyles() {
      if (this.artistData.length > 0) {
        let artist = this.artistData[0];
        console.log('Applying styles for artist:', artist);

        const backgroundColor = artist.style_profile_body_colour_text || '#f6f6f6';
        document.querySelector('.body-wrapper').style.setProperty('--background-color', backgroundColor);

        if (artist.background_image_image) {
          document.body.style.setProperty('--background-image-url', `url(${artist.background_image_image})`);
          document.querySelector('.body-wrapper').style.setProperty('--background-color-opacity', this.hexToRgba(backgroundColor, 0.6));
          console.log('Background image applied:', artist.background_image_image);
        } else {
          document.body.style.setProperty('--background-image-url', 'none');
          document.querySelector('.body-wrapper').style.setProperty('--background-color-opacity', backgroundColor);
          console.log('Background image removed');
        }
      } else {
        document.body.style.setProperty('--background-image-url', 'none');
        document.querySelector('.body-wrapper').style.setProperty('--background-color-opacity', '#f6f6f6');
        console.log('Default background applied');
      }
    },





    ...mapActions(['updateArtist']),
    async fetchArtistData() {
      if (!this.artist) return; // if artist is not set, do nothing
      try {
        const response = await fetch(`${this.baseURL}/proxy/artistData?id=${this.artist}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        this.artistData = await response.json();
        console.log('Artist data fetched:', this.artistData);
      } catch (error) {
        console.error('Error fetching artist data:', error);
      }
    },
    async fetchCategoriesAndStyles() {
      try {
        const styleResponse = await fetch(`${this.baseURL}/proxy/meilisearch/styles`);
        if (!styleResponse.ok) throw new Error(`HTTP error! status: ${styleResponse.status}`);
        const styles = await styleResponse.json();
        console.log('Fetched styles:', styles);

        this.styleOptions = styles;
      } catch (error) {
        console.error('Error fetching styles:', error);
      }
    }
,

    openCaptionModal() {
      this.captionModalVisible = true;
    },
    closeCaptionModal() {
      this.captionModalVisible = false;
    },
    addFancyboxAttributes() {
      const images = document.getElementById('search-results').querySelectorAll('img');
      images.forEach((img) => {
        const action_html = img.getAttribute('data-action_html');
        const username = img.getAttribute('data-username');

        img.setAttribute('data-fancybox', 'gallery');
        img.setAttribute('data-src', img.src);
        img.setAttribute('data-caption', action_html);
        img.setAttribute('data-username', username);
      });
      console.log('Fancybox attributes added.');
    },
    setArtist(artist) {
      console.log(`Artist set to: ${artist}`);
      window.vueInstance.artist = artist;
      this.updateArtist(artist);  // Update Vuex store
      console.log(`Artist in Vuex store after update: ${this.artist}`);
      window.vueInstance = this;  // Re-expose instance after setting artist
      this.performSearch();  // Trigger search based on the new artist value
      this.artist = artist;
      this.profileUsername = artist;
      Fancybox.close(); // Close the Fancybox modal

      // Ensure the DOM is updated before scrolling
      this.$nextTick(() => {
        // Try scrolling the container
        const container = this.$refs.scrollContainer;
        if (container) {
          container.scrollTop = -30;
          container.scrollIntoView({ behavior: 'smooth', block: 'start' });
          window.scrollTo({ top: -30, behavior: 'smooth' });
        } else {
          // Fallback: Scroll the window
          window.scrollTo({ top: -30, behavior: 'smooth' });
        }
      });
    },
    initializeFancybox() {
      if (this.isFancyboxInitialized) return;
      this.isFancyboxInitialized = true;

      Fancybox.bind('[data-fancybox="gallery"]', {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: ["zoomIn", "zoomOut", "toggle1to1", "rotateCCW", "rotateCW", "flipX", "flipY"],
            right: ["slideShow", "thumbs", "close"],
          },
        },
        Thumbs: false,
        loop: true,
        transitionEffect: "slide",
        transitionDuration: 500,
        hideScrollbar: false,
        on: {
          // eslint-disable-next-line no-unused-vars
          reveal: (fancybox, slide) => {
            this.attachClickEventListeners();  // Ensure event listeners are attached
          }
        },
      });
      console.log('Fancybox initialized.');
    },

    attachClickEventListeners() {
      const fancyboxContent = document.querySelectorAll('.fancybox__container .instagram-link, .fancybox__container .profile-link');
      fancyboxContent.forEach(el => {
        el.addEventListener('click', function(event) {
          event.preventDefault();
          const username = el.getAttribute('data-username');

          if (el.classList.contains('profile-link')) {
            this.setArtist(username);
            this.searchQuery = '';
            console.log(`Profile link clicked: ${username}`);
          } else if (el.classList.contains('instagram-link')) {
            console.log(`https://ig.me/m/${username}`)
            window.open(`https://ig.me/m/${username}`, '_blank');
          }
        }.bind(this));  // Bind `this` to the Vue instance
      });
      console.log('Click event listeners added.');
    },

    async handleArtistParam() {
      if (!this.artist) {
        const urlParams = new URLSearchParams(window.location.search);
        this.artist = urlParams.get('artist');
      }

      const profileHeader = document.getElementById('profile-header');

      if (profileHeader) {
        if (!this.artist || this.artist === 'all') {
          profileHeader.classList.add('hidden');
        } else {
          profileHeader.classList.remove('hidden');
        }
      }
    },
    onScroll() {
      const bodyWrapper = document.querySelector('.body-wrapper');
      if (bodyWrapper.scrollTop > 100) {
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }

      if (bodyWrapper.scrollTop + bodyWrapper.clientHeight >= bodyWrapper.scrollHeight - 600 && !this.isLoading) {
        this.currentPage++;
        this.performSearch(this.currentPage);
      }
    },
    onSearchInput() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(() => this.resetSearch(), this.typingInterval);

      const inputElement = document.getElementById('search-input');
      if (this.searchQuery.trim() === '') {
        inputElement.classList.add('dim-background');
        inputElement.classList.remove('hide-background');
      } else {
        inputElement.classList.remove('dim-background');
        inputElement.classList.add('hide-background');
      }

    },
    selectAllCategories() {
      this.selectAll(document.getElementById('category-options'));
    },
    clearAllCategories() {
      this.clearAll(document.getElementById('category-options'));
    },
    selectAllStyles() {
      this.styleOptions.forEach(style => {
        if (!this.selectedStyles.includes(style)) {
          this.selectedStyles.push(style);
        }
      });
      console.log("Selected all styles:", this.selectedStyles);
      this.updateCheckboxes();
      this.performSearch(1);
    },
    clearAllStyles() {
      this.selectedStyles = [];
      console.log("Cleared all styles:", this.selectedStyles);
      this.updateCheckboxes();
      this.performSearch(1);
    },
    selectAll(filterContainer) {
      const checkboxes = filterContainer.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => checkbox.checked = true);
      this.resetSearch();
    },
    clearAll(filterContainer) {
      const checkboxes = filterContainer.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(checkbox => checkbox.checked = false);
      this.resetSearch();
    },
    toggleFilterSection() {
      this.filterSectionExpanded = !this.filterSectionExpanded;
    },
    updateCheckboxes() {
      this.$nextTick(() => {
        const styleContainer = document.getElementById('style-options');
        if (styleContainer) {
          const checkboxes = styleContainer.querySelectorAll('input[type="checkbox"]');
          checkboxes.forEach(checkbox => {
            checkbox.checked = this.selectedStyles.includes(checkbox.value);
          });
          console.log("Updated checkboxes based on selectedStyles:", this.selectedStyles);
        }
      });
    },
    resetSearch() {
      console.log('resetSearch triggered');
      this.currentPage = 1;
      document.getElementById('search-results').innerHTML = '<div class="grid-sizer"></div>';
      this.loadedResults.clear();
      this.performSearch();
    },

    async clearSearch() {
      this.searchQuery = '';
      this.resetSearch();
    },

    performSearch(page = 1, limit = this.resultsPerPage) {
      this.handleArtistParam();
      if (this.isLoading) return;
      this.isLoading = true;

      const query = this.searchQuery;
      const selectedCategories = this.selectedCategories;
      const selectedStyles = this.selectedStyles;
      const categoriesParam = selectedCategories.join(',');
      const stylesParam = (selectedStyles.length === 0 || selectedStyles.length === document.querySelectorAll('input[name="style"]').length)
          ? this.styleOptions.join(',')
          : selectedStyles.join(',');

      console.log('Performing search with the following parameters:');
      console.log('Query:', query);
      console.log('Selected Categories:', selectedCategories);
      console.log('Categories Param:', categoriesParam);
      console.log('Selected Styles:', selectedStyles);
      console.log('Styles Param:', stylesParam);
      console.log('Page:', page);
      console.log('Limit:', limit);

      this.performSearchWithParams(query, categoriesParam, stylesParam, page, limit);
    },


    async performSearchWithParams(query, categories, styles, page, limit) {
      const url = new URL(`${this.baseURL}/proxy/meilisearch/search`);

      url.searchParams.append('query', query);
      if (categories) {
        url.searchParams.append('categories', categories);
      }
      if (styles) {
        url.searchParams.append('prompt_styles', styles);
      }
      if (this.artist) {
        url.searchParams.append('artist', this.artist);
      }
      url.searchParams.append('page', page);
      url.searchParams.append('limit', limit);

      // Debug URL Construction
      console.log('Constructed URL for search:', url.toString());

      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();

        if (page === 1) {
          if (this.masonryInstance) {
            this.masonryInstance.destroy();
          }
          document.getElementById('search-results').innerHTML = '<div class="grid-sizer"></div>';
        }

        if (Array.isArray(data)) {
          const fragment = document.createDocumentFragment();
          const grid = document.querySelector('.grid');
          data.forEach(item => {
            const img = document.createElement('img');
            img.src = item.cdn_media_url;
            img.alt = item.prompt_category;
            img.setAttribute('data-permalink', item.permalink);
            img.setAttribute('data-username', item.username);
            img.setAttribute('data-action_html', item.action_html);

            const info = document.createElement('div');
            info.className = 'overlay';
            info.innerHTML = `
        ${item.username}<br>
        ${item.prompt_category}<br>
        ${item.prompt_style}<br>
        ${new Date(item.post_timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      `;

            const container = document.createElement('div');
            container.className = 'grid-item';
            container.appendChild(img);
            container.appendChild(info);

            fragment.appendChild(container);
          });

          grid.appendChild(fragment);

          this.masonryInstance = new Masonry(grid, {
            transitionDuration: '0.0s',
            gutter: 15,
            resize: true,
            initLayout: true,
            columnWidth: '.grid-sizer',
            percentPosition: true
          });

          imagesLoaded(grid, () => {
            this.masonryInstance.layout();
            this.initializeFancybox();
            this.addFancyboxAttributes();
          });
        }

        this.isLoading = false;
      } catch (error) {
        console.log('Error URL:', url.toString());
        console.error('Error fetching search results:', error);
        this.isLoading = false;
      }
    }
    ,

  }

};
</script>

<style scoped>
@import './assets/styles.css';
/* Add your scoped styles here */

.custom-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  border-radius: 20px;
  border: 5px solid black;
  padding: 20px;
  width: 80%;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Dynamic styles for profile-body */
.profile-card {
  background-color: v-bind('profileBodyStyle.backgroundColor');
  color: v-bind('profileBodyStyle.color');
}

/* Apply button styles globally */
button {
  background-color: v-bind('buttonStyle.backgroundColor') !important;
  color: v-bind('buttonStyle.color') !important;
  border: v-bind('buttonStyle.border') !important;
}

/* Scoped styles for the ProfileHeader component */
.profile-card {
  border-radius: 20px;
  padding: 20px;
  max-width: 1000px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.profile-info {
  margin-top: 10px;
}

.profile-name {
  font-size: 20px;
  font-weight: bold;
}

.profile-description {
  margin-top: 15px;
  font-size: 14px;
}

.book-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.book-button:hover {
  background-color: #7a5600; /* Darker button color on hover */
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  /* Apply dynamic background color and image */
  /*background-color: v-bind('pageStyle.backgroundColor'); */
  /*background-image: v-bind('pageStyle.backgroundImage');
  background-color: v-bind('profileBodyStyle.backgroundColor');*/
}


.app-body {
  background: none !important;
  /*overflow-y: auto; /* or scroll */
  height: 100vh; /* Ensure it has a defined height */
}








.filter-modal-content {
  background-color: white;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: auto; /* Adjust based on content */
  max-width: 90%; /* Limit width for large screens */
  max-height: 90%; /* Limit height for large screens */
  overflow: auto; /* Add scroll if content overflows */
  z-index: 1051; /* Above the backdrop */
  display: flex;
  flex-direction: column;
}


.close-modal {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: none;
  background: none !important;
}


.search-container {
  display: flex;
  gap: 8px;
}

.address-input,
.radius-select {
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
}


#search-inputt::before {
  content: '';
  position: absolute;
  left: 10px; /* Adjust the position of the icon */
  top: 50%;
  transform: translateY(-50%);
  width: 20px; /* Adjust the width of the icon */
  height: 20px; /* Adjust the height of the icon */

  background-size: contain;
  background-repeat: no-repeat;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 50vw;
  flex-direction: row
}

#search-input {
  flex: 1;
  width: 50%; /*calc(100% - 40px) !important;*/
  padding-right: 0px; /* Adjust this value to make space for the search icon */
  padding-left: 0px; /* Adjust this value based on your icon's size */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.3s, opacity 0.3s ease;

}

.search-wrapper:not(.has-artist) #search-input {
  background-image: url('https://cdn.tattstack.io/assets/Search.png');
}

.search-wrapper:not(.has-artist) #search-input.dim-background {
  background-image: url('https://cdn.tattstack.io/assets/Search.png');
  opacity: 0.2;
}

.search-wrapper:not(.has-artist) #search-input.hide-background {
  background-image: none;
}

.search-icon-container {
  width: 40px !important;
  height: 40px !important;
  display: flex !important; /* Use Flexbox */
  justify-content: center !important; /* Center horizontally */
  align-items: center !important; /* Center vertically */
}

.search-icon-container .iconify {
  font-size: 35px !important;
}

#icon-container-left {
  padding-left: 4px;
}
#icon-container-right {
  padding-right: 4px;
}
</style>
