<script setup>
import { vMaska } from "maska/vue"
</script>

<template>
  <transition name="slidefade">
    <div v-if="showModal" class="modal-backdrop" @click="closeModal">
        <div v-if="showModal" class="modal-content" @click.stop>
          <button class="close-modal" @click="closeModal">x</button>
          <h2>Submit a tattoo inquiry to {{ artistData[0].display_name_text }}</h2>

          <div class="form-section">
            <div class="form-header">Your Information</div>
            <div class="form-group">
              <input type="text" v-model="form.firstName" placeholder="First Name">
              <input type="text" v-model="form.lastName" placeholder="Last Name">
              <input type="text" v-model="form.phone" v-maska="'(###) ###-####'" placeholder="Phone #">
              <input type="email" v-model="form.email" placeholder="Email">
              <input type="text" v-model="form.instagram" placeholder="Instagram">
              <input type="text" v-model="form.pronouns" placeholder="Pronouns">
            </div>
          </div>

          <div class="form-section">
            <div class="form-header">Contact me by</div>
            <div class="form-group">
              <label><input type="checkbox" v-model="form.contactByEmail"> Email</label>
              <label><input type="checkbox" v-model="form.contactBySms"> SMS</label>
              <label><input type="checkbox" v-model="form.contactByInstagram"> Instagram</label>
            </div>
          </div>

          <div class="form-section">
            <div class="form-header">Your Tattoo Idea</div>
            <div class="form-group">
              <input type="text" v-model="form.title" placeholder="Title">
              <textarea v-model="form.description" placeholder="Description"></textarea>
              <input type="text" v-model="form.placement" placeholder="Placement">
              <input type="number" v-model="form.size" placeholder="Size (inches)" @input="validateSize">
            </div>
          </div>

          <div class="form-section">
            <div class="form-header">Reference Images</div>
            <div class="form-group">
              <input type="file" @change="handleFileUpload" multiple>
            </div>
          </div>

          <div class="form-group">
            <textarea v-model="form.additionalInfo" :placeholder="`Anything else you'd like ${artistData[0].display_name_text} to know?`"></textarea>
          </div>

          <div class="form-group">
            <label>
              <input type="checkbox" v-model="form.agreeToShare">
              By clicking submit I agree to share my contact information with TattStack and House of Fantasy
            </label>
          </div>

          <button @click="submitForm">Submit</button>
        </div>
    </div>
  </transition>
</template>


<script>



export default {
  name: 'InquiryModal',
  components: {
    //InputFacade
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    artistData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isMounted: false,
      form: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        instagram: '',
        pronouns: '',
        contactByEmail: false,
        contactBySms: false,
        contactByInstagram: false,
        title: '',
        description: '',
        placement: '',
        size: '',
        additionalInfo: '',
        agreeToShare: false,
      }
    };
  },
  mounted() {
    this.isMounted = true;
    console.log('InquiryModal mounted');
  },
  watch: {
    showModal(newVal) {
      const bodyWrapper = document.querySelector('.body-wrapper');
      if (newVal) {
        bodyWrapper.classList.add('no-scroll');
        this.scrollToTop()
      } else {
        bodyWrapper.classList.remove('no-scroll');
      }
    }
  },
  methods: {
    scrollToTop() {
      const bodyWrapper = document.querySelector('.body-wrapper');
      bodyWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    },
    closeModal() {
      this.$emit('close');
    },
    handleFileUpload(event) {
      const files = event.target.files;
      console.log('Files uploaded:', files);
      // Handle file upload
    },
    submitForm() {
      console.log('Submitting form with data:', this.form);
      // Validate the form before submission
      if (this.validateForm()) {
        // Handle form submission
      } else {
        alert('Please correct the errors in the form.');
      }
    },
    validateSize() {
      this.form.size = this.form.size.replace(/\D/g, '');
    },
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

      if (!this.form.firstName || !this.form.lastName || !this.form.email || !this.form.phone || !this.form.size) {
        return false;
      }

      if (!emailRegex.test(this.form.email)) {
        return false;
      }

      if (!phoneRegex.test(this.form.phone)) {
        return false;
      }

      if (isNaN(this.form.size)) {
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dim the background */
  display: flex;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Make sure it's on top */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%; /* Limit width for large screens */
  max-width: 600px; /* Adjust based on your needs */
  overflow: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  border: none;
  background: none;
}

h2 {
  text-align: center;
}

.form-section {
  margin-bottom: 20px;
}

.form-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group input,
.form-group textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}




</style>
