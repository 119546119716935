// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store.js';  // Explicitly include the file extension
import Maska from 'maska';

const app = createApp(App);
app.use(store);
app.use(Maska);
app.mount('#app');
